import SubHeader from "@/components/SubHeader";
import AddRoleAndPermissionModal from "@shared/components/roles-and-permissions/add-role-permission-modal";
import axios from "@/axios";
const ModalConfirm = () => import("@shared/modal-confirm");
const ListModal = () => import("@shared/components/listModal");
import { checkPermission } from "@shared/utils/functions"
import Loader from "@shared/loader"
import dropdown from "@shared/components/dropdown-base";
import { DateTime } from "luxon";
import { format , parse } from "date-fns";
import DatePicker from "vue2-datepicker";
import { onlyUnique } from "@shared/plugins/utils.js";
import { uuid } from "vue-uuid";
const Drawer = () => import("@shared/components/drawer");
import {FormatDateNew} from "@/plugins/functions";

export default {
    name: "roles-and-permissions",
    components: {
        SubHeader,
        AddRoleAndPermissionModal,
        ModalConfirm,
        ListModal,
        Loader,
        Drawer,
        dropdown,
        DatePicker
    },
    title: "Roles and Permissions",
    props: {},
    data: () => ({
        columns: [
            {
                label: "Role",
                field: "name",
                config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'action_button',
                },
            },
            {
                label: "Permission",
                field: "permissions_count",
                // config: {
                //     filter: true,
                //     alignCenter: true,
                //     field_type: 'action_button',
                // },
            },
            {
                label: "Users",
                field: "users_count",
                // config: {
                //     filter: true,
                //     alignCenter: true,
                //     field_type: 'action_button',
                // },
            },
            {
                label: "Group",
                field: "groups_count",
                // config: {
                //     filter: true,
                //     alignCenter: true,
                //     field_type: 'action_button',
                // },
            },
            {
                label: "Type",
                field: "type",
                config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'action_button',
                },
            },
            {
                label: "Action",
                field: "actions",
            },
        ],
        // dummy Data
        rows: [],
        serverParams: {},
        filterRefs: [],
        filterOptions: {},
        searchLoading:{},
        loadingStates: {
            fetchingRoles: false,
            createRole: false,
            deleteRole: false,
            permission: false,
            listModal: false,
        },
        permissionsList: [],
        currentActiveModalList: {
            lists: [],
            title: "",
        },
        currentPageData: {
            page: 1,
            limit: 10,
        },
        serchValues: "",
        currentPermission: null,
    }),

    computed: {
        getPermissionsList() {
            return this.permissionsList;
        },
        getmodalList() {
            return this.currentActiveModalList?.lists?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.toLowerCase()));
        },
        isFilters() {
            return this.serverParams && Object.entries(this.serverParams).length;
          },
          columnFilterOptions() {
            return this.getFilterOptions(this.columnOption, this.rows);
          },
          columnOption() {
            return this.columns.map((el) => ({
              ...el,
              config: 
              {
                  ...el.config,
                  isLoading: !!this.searchLoading[el.field] || false,
              },
            }));
          },
          computedColumns() {
            // use option list from server if it is:
            const options = this.columnFilterOptions;
            // had to check for empty object
            if (options && Object.entries(options).length) {
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                if (col.prefetchedOptions && col.prefetchedOptions.length) {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: col.prefetchedOptions,
                    },
                  };
                } else {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: options[col.field],
                    },
                  };
                }
              });
            } else {
              // TODO remove/rework default filters
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                return {
                  ...col,
                  filterOptions: filtered
                    ? {
                      enabled: true,
                      placeholder: "All",
                      filterDropdownItems: this.rows
                        .filter((row) => row[col.field])
                        .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                        .filter(onlyUnique),
                    }
                    : undefined,
                };
              });
            }
          }
    },
    async mounted() {
        await this.getRolesLists();
    },
    methods: {
        FormatDateNew,
        checkPermission,
        async handleShowModal() {
            this.$refs["add-role-permission-modal"].showModal();
            await this.getPermissions();
        },
        handleCloseModal() {
            this.$refs["add-role-permission-modal"].closeModal();
        },
        async getRolesLists() {
            this.loadingStates.fetchingRoles = true;
            try {
                const filterParams = this.makeFilterParams();
                let qParams
                qParams = new URLSearchParams(this.currentPageData);
                if (filterParams) {
                    qParams = new URLSearchParams({...this.currentPageData, ...filterParams});
                }
                let { data } = await axios.get(`tenant-role/?${qParams}`);
                this.rows = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingStates.fetchingRoles = false;
        },
        async createRoles({ roles_and_permission }) {
            roles_and_permission.name = roles_and_permission.name.trim();
            if(roles_and_permission.name.length === 0){
                 return;
            }
            roles_and_permission = { ...roles_and_permission, permissions: this.permissionsList };
            this.loadingStates.createRole = true;
            try {
                let { data } = await axios.post(`tenant-role/`, roles_and_permission);
                if (data && data.data && data.data.id) {
                    this.$router.push({
                        name: "roles-and-permissions-details",
                        params: { id: data.data.id },
                    });
                }
                this.getRolesLists();
                // this.rows.push({ ...roles_and_permission, id: data.id, permissions_count: 0, users_count: 0, groups_count: 0,  });
                this.handleCloseModal();
                this.$toast.success(data.message || "Role created!");
            } catch (error) {
                console.log(error, ">>>>error");
                this.$toast.error(error.response.data.detail || "Failed to created role!");
            }
            this.loadingStates.createRole = false;
        },
        async deleteRole(role_id) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Role  will be deleted permanently. Please confirm to continue deleting this role.",
                buttonText: 'Confirm'
            });
            if (promise) {
                this.loadingStates.deleteRole = true;
                try {
                    let { data } = await axios.delete(`tenant-role/${role_id}`);
                    this.rows = this.rows.filter((el) => el.role_id !== role_id);
                    this.$toast.success(data.message || "Role deleted!");
                } catch (error) {
                    console.log(error, ">>>>error");
                    this.$toast.error(error.response.data.detail || "Failed to delete role!");
                }
                this.loadingStates.deleteRole = false;
            }
            this.$refs["confirm-popup"].close();
        },
        async getPermissions() {
            this.loadingStates.permission = true;
            try {
                let { data } = await axios.get(`tenant-role/permissions`);
                console.log(data, "permissions");
                this.permissionsList = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingStates.permission = false;
        },
        handleRoleEditClick(role_id) {
            this.$router.push({
                name: "roles-and-permissions-details",
                params: {
                    id: role_id,
                },
            });
        },
        selectAllPer({ input, index, all }) {
            input = input.target.checked;
            if (all) {
                this.permissionsList = this.permissionsList.map((el, id) => {
                    if (id === index) {
                        return { ...el, permissions: el.permissions.map((per) => ({ ...per, selected: input })) };
                    } else {
                        return el;
                    }
                });
                console.log(this.permissions);
            } else {
                this.permissionsList = this.permissionsList.map((el) => {
                    let permissions_checked_count = el.permissions.filter((el) => el.selected).length;
                    if (permissions_checked_count === el.permissions.length) {
                        return { ...el, selected: true };
                    } else {
                        return { ...el, selected: false };
                    }
                });
            }
        },
        async showList(row, type) {
            this.$refs["roles-permission-user-group-modal"].showListModal();
            this.currentActiveModalList = {
                lists: [],
                title: "",
            };
            this.currentActiveModalList.title = type;
            await this.fetchModalData(row.role_id, type.toLowerCase());
        },

        async fetchModalData(role_id, type) {
            this.loadingStates.listModal = true;
            let url = `tenant-role/${role_id}/${type}`;
            try {
                let { data } = await axios.get(url);
                if (type.toLowerCase() === "permissions") {
                    let permission = [];
                    data.forEach((el) =>
                        el.permissions.forEach((per) => {
                            if (per.selected) {
                                permission.push(per);
                            }
                        })
                    );
                    this.currentActiveModalList.lists = permission;
                } else {
                    this.currentActiveModalList.lists = data.filter((el) => el.selected);
                }
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loadingStates.listModal = false;
        },
        handleFilterResult(event) {
            this.serchValues = event;
        },
        openDrawer() {
            this.$refs.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
        },
        viewPermissionsInfo(event) {
            this.currentPermission = null;
            if (event && event.description) {
                this.currentPermission = event;
                this.openDrawer();
            }
        },
        async onPageChange() {
            await this.$nextTick();
            const scrollTarget = document.getElementById('vgt-table');
            if (scrollTarget) {
              scrollTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
        },
        updateColumnFilters(column, value /* , callback */) {
            let filterValObj = {
              ...this.serverParams,
              [column.query_key || column.field]: value ? {
                ...value,
                field_type: column.field_type,
              } : null,
            };
            if (!filterValObj[column.query_key || column.field]) {
              delete filterValObj[column.query_key || column.field];
            }
            if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
              let date = DateTime.fromISO(value.name).toISODate()
              if (date == null) {
                const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                date = format(parsedDate, 'yyyy-MM-dd');
                if (filterValObj.from_created_at) {
                  filterValObj.from_created_at.name = date.toString().substring(10, 0)
                } else {
                  filterValObj.from_due_date.name = date.toString().substring(10, 0)
                }
              }
              Object.assign(filterValObj, {
                [`to_${column.field}`]: {
                  id: date.ts,
                  name: date.toString().substring(10, 0),
                  field_type: column.field_type
                },
              });
            }
            if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
              let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
              Object.assign(filterValObj, {
                [column.config.query_keys['start']]: {
                  id: start.ts,
                  name: value[0] ? start.toString() : null,
                  field_type: column.field_type
                },
              })
              Object.assign(filterValObj, {
                [column.config.query_keys['end']]: {
                  id: end.ts,
                  name: value[1] ? end.toString() : null,
                  field_type: column.field_type
                },
              })
            }
            this.onColumnFilter( {
              columnFilters: filterValObj,
            });
        },
        getFilterOptions(columns, row) {
            return columns.reduce((result, curr) => {
              result[curr.field] = row
                .filter((row) => row[curr.field])
                .map((row) => row[curr.field])
                .filter(onlyUnique)
                .map((el) => (
                  { 
                    id: uuid.v4(), name: el 
                  }));
                  if (curr?.field == 'order_date' || curr?.field == 'sale_date') {
                    result[curr.field] = result[curr.field]?.map(el => {
                      return {
                        ...el,
                        name: FormatDateNew(new Date(el.name)),
                        label: el.name
                      }
                    })
                  }
                  return result;
                }, 
            {});
        },
        makeFilterParams() {
            let filterParams = {};
            if (this.serverParams) {
              Object.keys(this.serverParams).map((key) => {
                if (key == 'order_date' || key == 'sale_date') {
                  filterParams[key] = this.serverParams[key] ? this.serverParams[key]["label"] : delete filterParams[key];
                } else {
                  filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                }
                if (this.serverParams[key]["name"] === undefined) {
                  delete filterParams[key];
                }
              });
            }
            return filterParams;
        },
        async clearFilters() {
            this.serverParams = null;
            await this.getRolesLists();
          },
          async onColumnFilter({ columnFilters }) {
            this.currentPageData.page = 1;
            this.serverParams = columnFilters ? { ...columnFilters } : null;
            await this.getRolesLists();
          },
          selectionChanged(e) {
            // console.log('e :>> ', e);
            if (e) {
              this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
              this.$emit('on-selected-rows-change', e.selectedRows)
            }
          },
        getFilterValue(col) {
            // isFilters ? filters[column.field] : null
            if (this.isFilters) {
              return this.serverParams[col.field] ?? this.serverParams[col.query_key];
            } else return null;
          },
        searchFilter(column, value) {
            this.$emit("search-filter", { column, value });
          },
    },
};
